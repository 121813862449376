import React, {Component} from 'react';
import { Helmet } from "react-helmet";
// import { Link } from "react-router-dom";
import classes from './Home.module.scss';
// import Phone from '../../components/Shared/Phone';
import RentalItems from "../Rentals/RentalLoop";
import BookNow from '../../components/Shared/BookNow';
import And from '../../components/Shared/And';
// import Video from '../../components/Video/Video';
// import beachVideo from '../../assets/images/beach.mp4';
import stackGear from '../../assets/images/beachGear2.jpg';
import stackPack from '../../assets/images/beachGear5.jpg';
import stackPaddle from '../../assets/images/waterSports1.jpg';
import rating from '../../assets/images/rating2.png';
import ratingW from '../../assets/images/ratingWhite.png';
// import reviewImg from '../../assets/images/beachEyes.jpg';
import reviewImg from '../../assets/images/team1.jpg';


const arrow = [
  <svg baseProfile="tiny-ps" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 15" width="20" height="15">
    <path id="Shape_7Y" className="shp0" d="M18.71 8.22L-0.01 8.22L-0.01 6.73L18.71 6.73L18.71 8.22ZM12.48 14.98L11.43 13.92L17.88 7.47L11.43 1.03L12.48 -0.03L19.99 7.47L12.48 14.98Z" />
  </svg>
]

class Home extends Component {
  render() {
    const Level2items = RentalItems.filter(item =>
      item.level === '2'
    );
 
    const serviceLoopB = Level2items.map((item) => 
      <li key={item.id} className={[classes.slide, classes["item" + item.id]].join(' ')}>
        <a href={item.link} className={classes.box}>
          <p className={classes.title}>{item.brTitle}</p>
        </a>
      </li>
    );

    return (
      <div className={classes.Home}>
        <Helmet>
          <title>Beach Chairs, Tents & Vacation Activities in Gulf Shores, Orange Beach & Ft Morgan</title>
          <meta name="description" content="From beach chairs, umbrellas & tents, to kayaks & paddleboards, even yoga & boat charters, we deliver vacation fun to Gulf Shores, Orange Beach & Ft Morgan, AL." />
        </Helmet>

        <div className={[classes.fullSec, classes.titleBox].join(' ')}>
          <h1 className={classes.style}>Beach Chairs &middot; Shade &middot; Supplies <And where="homeTitle" color="#f8a61a" /> Fun</h1>
          <h2>For Your Alabama Gulf Coast Vacation</h2>
          <div className={[classes.linkRow].join(' ')}><BookNow where="homeTitle" addClass="gsLinkLg" /></div>
        </div>

        <div className={[classes.fullSec, classes.hs1].join(' ')}>
          <div className={[classes.insideXl, classes.ix1].join(' ')}>
            <div className={[classes.stackWrap, classes.stack1].join(' ')}>
              <div className={[classes.stackCol, classes.si1].join(' ')}>
                <a href="https://fareharbor.com/embeds/book/gulfshoresalbeachrentals/?full-items=yes&flow=918648" className={[classes.sitem].join(' ')}>
                  <img src={stackGear} alt="Beach chair and shade rentals from Orange Beach to Ft Morgan, Alabama" className={classes.fwImg} />
                  <div className={classes.stbox}>
                    <p className={classes.title}>Beach Gear</p>
                    <p>Chairs, umbrellas, &amp; tents</p>
                    <span className={classes.arrow}>{arrow}</span>
                  </div>
                </a>
              </div>

              <div className={[classes.stackCol, classes.si2].join(' ')}>
                <a href="https://fareharbor.com/embeds/book/gulfshoresalbeachrentals/?full-items=yes&flow=910867" className={[classes.sitem].join(' ')}>
                  <img src={stackPack} alt="Beach supply rental packages in Orange Beach, Alabama" className={classes.XXfwImg} />
                  <div className={classes.stbox}>
                    <p className={classes.title}>Packages</p>
                    <p>Check out the latest deals!</p>
                    <span className={classes.arrow}>{arrow}</span>
                  </div>
                </a>
              </div>

              <div className={[classes.stackCol, classes.si3].join(' ')}>
                <a href="https://fareharbor.com/embeds/book/gulfshoresalbeachrentals/?full-items=yes&flow=918653" className={[classes.sitem].join(' ')}>
                  <img src={stackPaddle} alt="Paddleboard and kayak rentals in Gulf Shores and Orange Beach" className={classes.XXfwImg} />
                  <div className={classes.stbox}>
                    <p className={classes.title}>Watersports</p>
                    <p>Kayaks &amp; Paddleboards</p>
                    <span className={classes.arrow}>{arrow}</span>
                  </div>
                </a>
              </div>
            </div>
          </div>
            
          <div className={[classes.insideXl, classes.ix2].join(' ')}>
            <div className={[classes.stackWrap, classes.sw2].join(' ')}>
              <div className={[classes.fullSec, classes.slideContainer].join(' ')} data-uk-slider>
                <div className={[classes.scWrap, "uk-position-relative uk-visible-toggle"].join(' ')} tabIndex="-1">
                  <ul className={[classes.sliderItems, classes.reviews, "uk-slider-items uk-child-width-1-2 uk-child-width-1-4@s uk-child-width-1-4@m uk-child-width-1-5@l XXuk-grid"].join(' ')}>
                    {serviceLoopB}
                  </ul>
                </div>

                <button className={[classes.slideNav, classes.prev, "uk-position-center-left uk-position-small"].join(' ')} href="#" data-uk-slidenav-previous data-uk-slider-item="previous"></button>
                <button className={[classes.slideNav, classes.next, "uk-position-center-right uk-position-small"].join(' ')} href="#" data-uk-slidenav-next data-uk-slider-item="next"></button>
              </div>            
            </div>
          </div>
        </div>

        <div className={[classes.fullSec, classes.hs2, classes.textSec].join(' ')}>
          <div className={classes.med2Def}>
            <p className={classes.caption}>Forget the <b>complication</b> <span>&middot;</span> Enjoy some <b>relaxation</b></p>
            <h3>Make the most of your <b>vacation!</b></h3>
            <div className={[classes.fullSec, classes.textBox].join(' ')}>
              <p>
                Rent your beach supplies from us, and you get more than just extra space for traveling. <br className={classes.br1} />Gulf Shores 
                Beach Rentals has everything you need, from beach gear and activities, to grocery delivery. Take a look at our selection, and 
                place your order. Everything will be awaiting your arrival at your destination, so you can focus on friends, family, and fun!
              </p>
            </div>
          </div>
        </div>

        <div className={[classes.ctaboxFull, classes.fullRow].join(' ')}>
          <div className={classes.medDef}>
            <div className={[classes.col, classes.grid25, classes.col1].join(' ')}>
              <p className={classes.title}>5-7 Day<br/> Rentals</p>
            </div>
            
            <div className={[classes.col, classes.grid50, classes.mid].join(' ')}>
              <p>Get a head start on your trip! Reserve what you need today, and we'll handle the rest!</p>
            </div>
            
            <div className={[classes.col, classes.grid25, classes.col3, classes.links].join(' ')}>
              <BookNow where="white" addClass="gsLinkLg" />
            </div>
          </div>
        </div>

        <div className={[classes.fullSec, classes.reviewSec].join(' ')}>
          <div className={classes.inside}>
            {/* 1200x1554 */}
            <div className={classes.imgHlf}><img src={reviewImg} alt="Vacationers enjoying Gulf Shores beaches" /></div>

            <div className={classes.slideHlf}>
              <div className={[classes.fullSec, classes.slideContainer].join(' ')} data-uk-slider>
                <div className={classes.stars}>
                  <img src={rating} alt="Gulf Shores Beach Rentals 5-star service" className={classes.fwImg} />
                  <img src={ratingW} alt="5-star reviews of Gulf Shores Beach Rentals" className={classes.mobImg} />
                </div>

                <div className={[classes.scWrap, "uk-position-relative uk-visible-toggle"].join(' ')} tabIndex="-1">
                  <ul className={[classes.sliderItems, classes.reviews, "uk-slider-items uk-child-width-1-1@s uk-grid"].join(' ')}>
                    <li key="ss1" className={[classes.slide, classes.s2].join(' ')}>
                      <div className={[classes.inner].join(' ')}>
                        <p className={classes.quote}>"Amazing!!! Everything was as promised! Each morning we had chairs and an umbrella waiting on the beach and each night they were removed. It was like having our very own beach elf! Highly recommend!"</p>
                        <p className={classes.name}>- Lynn B.</p>
                      </div>
                    </li>
                    <li key="ss2" className={[classes.slide, classes.s1].join(' ')}>
                      <div className={[classes.inner].join(' ')}>
                        <p className={classes.quote}>"Love this company! It makes traveling so much easier knowing I don't have to haul such large items back and forth. Elizabeth and her staff are the bees knees! Highly recommend!"</p>
                        <p className={classes.name}>- Summer M.</p>
                      </div>
                    </li>
                    <li key="ss2" className={[classes.slide, classes.s1].join(' ')}>
                      <div className={[classes.inner].join(' ')}>
                        <p className={classes.quote}>
                          I've used this company for years in the Fort Morgan and Gulf Shores area. Rain or shine, top notch service... It has saved many a back 
                          during our vacations not carrying all the equipment needed for kids and adults.
                        </p>
                        <p className={classes.name}>- Elizabeth E.</p>
                      </div>
                    </li>
                  </ul>
                  {/* I've used this company for years in the Fort Morgan and Gulf Shores area. Rain or shine, top notch service. When our tent was damaged in a storm, they 
                  were there within the hour to fix, even on July 4th. We did a yoga class and it was excellent. The massage day was also fun. It has saved many a back 
                  during our vacations not carrying all the equipment needed for kids and adults.
                  
                  We rented the set up/take down canopy and chair package in West Beach across from Sanibel.  We appreciated the convenience and were thankful for the shade and set 
                  up by 10 every morning.
                  Tina H.

                  Super nice people and very easy process. We rented two chairs and an umbrella for a week this June. We will use them again!
                  Valerie M. */}
                </div>

                <ul className={[classes.dotNav, "uk-slider-nav uk-dotnav uk-flex-center uk-margin ogWhite"].join(' ')}></ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Home;