import React, {Component} from 'react';
import { Routes, Route, useNavigate, useLocation } from "react-router-dom"; // ** KPKP footer
// import { Routes, Route } from "react-router-dom";
import classes from './App.module.scss';
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import ToTopBtn from "./components/ScrollToTop/ToTopBtn";
import Phone from "./components/Shared/Phone";
import Email from "./components/Shared/Email";
import Social from "./components/Social/Social";
import Home from "./hoc/Home/Home";
import Info from "./hoc/Info/Info";
// import Rentals from "./hoc/Rentals/Rentals";
// import rentalItems from "./hoc/Rentals/RentalLoop";
import Airbnb from "./hoc/Airbnb/Airbnb";
import Groceries from "./hoc/Groceries/Groceries";
import logo from "./assets/images/gsbrLogo.png";

// ** KPKP footer = only if hiding top footer on home
export function withRouter( Child ) {
  return ( props ) => {
    const location = useLocation();
    const navigate = useNavigate();
    return <Child { ...props } navigate={ navigate } location={ location } />;
  }
}

class App extends Component {
  render() {
    // const rentalLoop = rentalItems.map((item) =>
    //   <Route
    //     path={"/" + [item.link]}
    //     key={item.id}
    //     element={ 
    //       <Rentals
    //         id={item.id}
    //         link={item.link}
    //         title={item.title}
    //         lightbox={item.lightbox}
    //         embed={item.embed}
    //         nickname={item.nickname}
    //         pageTitle={item.pageTitle}
    //         pageDesc={item.pageDesc}
    //       />
    //     }
    //   />
    // );

    let routes = (
      <Routes>
        <Route exact={true} path="/" element={<Home />} />
        <Route path="/information" element={<Info />} />
        <Route path="/rental-agreement" element={<Info />} />
        <Route path="/info" element={<Info />} />
        <Route path="/airbnb" element={<Airbnb />} />
        <Route path="/grocery-delivery" element={<Groceries />} />
        {/* {rentalLoop} */}
      </Routes>
    );

    // ** KPKP footer (whole const)
    const FootStyle = () => {
      if (window.location.pathname === '/') {
        return (<Footer type="homeStyle" />);
      }
      return (<Footer type="default" />);
    }
    const SneakyFooter = withRouter(FootStyle);
    const pathName = window.location.pathname.replace('-','');
    const wrapClass = pathName.replace('/', '');

    return (
      <div className={[classes.gsbrWrap, classes[wrapClass]].join(' ')}>
        <Header />
        <main className={classes.mainContent}>
          {routes}
        </main>
        <ToTopBtn />
        <SneakyFooter />

        <div id="contactModal" className={[classes.modalStyle, classes.contModal, "uk-flex-top"].join(' ')} data-uk-modal>
          <div className={[classes.imStyle, "uk-modal-dialog uk-modal-body uk-margin-auto-vertical"].join(' ')}>
            <button className="uk-modal-close-default" type="button" data-uk-close></button>

            <img src={logo} alt="Gulf Shores Beach Rentals logo" className={classes.logo} />
            <hr />
      
            <div className={classes.med4Def}>
              <div className={[classes.mainItem, classes.i1].join(' ')}>
                <p>Feel free to call, text, or email us if you have any questions, or need more information. We are happy to help!</p>
              </div>

              <div className={[classes.mainItem, classes.i2].join(' ')}>
                <div className={classes.item}><Phone where="modal" styleParent="appStyles" /></div>
                <div className={[classes.item, classes.itemEmail].join(' ')}><Email where="modal" styleParent="appStyles" /></div>
                <div className={classes.item}><Social where="modal" /></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default App;