import React from "react";
import classes from './Shared.module.scss';

const and = (props) => {
  return (
    <span className={[classes.ampSign, classes[props.where]].join(' ')}>
      {/* <?xml version="1.0" standalone="no"?> */}
      {/* <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 20010904//EN" "http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd"> */}
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 135.000000 206.000000" preserveAspectRatio="xMidYMid meet">
        {/* og-width="135.000000pt" og-height="206.000000pt"  */}
        <g transform="translate(0.000000,206.000000) scale(0.100000,-0.100000)"
        fill={props.color} stroke={props.stroke}>
          <path d="M535 1987 c-77 -26 -130 -60 -196 -126 -78 -78 -103 -138 -102 -241
          1 -88 23 -140 137 -330 53 -87 96 -164 96 -170 0 -7 -40 -55 -90 -106 -121
          -127 -183 -212 -235 -319 -52 -107 -70 -190 -62 -289 13 -178 144 -309 338
          -337 169 -25 321 35 509 200 l44 39 68 -101 c38 -55 76 -106 86 -114 26 -20
          72 -12 93 17 26 35 15 70 -72 215 l-70 119 44 86 c25 47 57 118 71 158 41 115
          33 162 -31 189 -33 13 -38 13 -62 -5 -16 -12 -43 -58 -71 -123 -27 -61 -51
          -104 -60 -104 -12 0 -133 187 -199 308 l-24 44 55 69 c118 149 189 273 229
          404 19 59 24 98 24 185 0 102 -2 114 -30 170 -37 73 -86 112 -181 144 -85 29
          -247 38 -309 18z m239 -177 c21 -6 50 -23 64 -37 23 -23 24 -29 19 -103 -8
          -120 -52 -203 -195 -368 -66 -76 -58 -81 -162 92 -82 136 -101 182 -101 250
          -1 44 3 52 43 93 23 25 66 56 93 70 43 21 60 23 125 19 41 -2 92 -10 114 -16z
          m-41 -1103 c127 -205 122 -194 101 -219 -26 -27 -188 -127 -237 -144 -80 -28
          -178 -10 -212 40 -41 58 0 190 100 325 62 85 135 162 146 155 5 -2 51 -73 102
          -157z"/>
        </g>
      </svg>
    </span>
  );
};
and.defaultProps = {
  color: "#314d7e",
  stroke: 'none'
}
export default and;
