import React, {Component} from 'react';
import { Helmet } from "react-helmet";
// import { Link } from "react-router-dom";
import classes from './Groceries.module.scss';
import Phone from '../../components/Shared/Phone';
import wave2 from '../../assets/images/shotWave2Yb.png';

const callBtn = (<>Call or Text Us to<br/><span>Arrange Pickup</span></>);

class Groceries extends Component {
  render() {
    return (
      <div className={classes.Groceries}>
        <Helmet>
          <title>Grocery Delivery in Gulf Shores & Orange Beach, Alabama</title>
          <meta name="description" content="Let Gulf Shores Beach Rentals do your grocery shopping, so you can enjoy your vacation. Our grocery delivery service will have your fridge stocked and ready for your arrival!" />
        </Helmet>


        <div className={[classes.inside, classes.inside1].join(' ')}>
          <div className={[classes.fullSec, classes.gsIntro].join(' ')}>
            <h1>Grocery Delivery</h1>
            {/* <p>Don't waste valuable vacation time fighting traffic and long lines! Let us do it for you!</p> */}

            <div className={classes.med3Def}>
            <p>Don't waste valuable vacation time fighting traffic and long lines. Let us do it for you! Read
              through the details and instructions on this page, or use the quick links below to get started.
            </p>
            <div className={classes.btnRow}>
              <a href="https://grocery.walmart.com/" target="_blank" rel="noreferrer noopener" className={[classes.qLink, classes.XXilLink].join(' ')}>
                Click Here to <br/><span>Order Groceries</span>
              </a>
              <Phone addClass="qLink" callText={callBtn} />
            </div>
            </div>
          </div>
        </div>

        <div className={[classes.kpGrid, classes.gs1].join(' ')}>
          <div className={classes.med3Def}>
            <div className={[classes.fullSec, classes.r1].join(' ')}>
              <div className={[classes.col, classes.left, classes.grid30].join(' ')}><h3>How does <br/>it work?</h3></div>

              <div className={[classes.col, classes.right, classes.grid67].join(' ')}>
                <ul>
                  <li>Submit your grocery list to <a href="https://grocery.walmart.com/" target="_blank" rel="noreferrer noopener" className={classes.hoverLink}>Walmart in 
                    Gulf Shores</a> (zipcode 36542)
                  </li>
                  <li><Phone where="grocery" callText="Call or text us" addClass="hoverLink" /> to arrange a pick up time. </li>
                  <li>All refrigerated and frozen items will be properly stored in the refrigerator/freezer of your vacation destination, so we will need the name and 
                    phone number of your rental property provider.</li>
                </ul>
              </div>
            </div>

            <div className={[classes.fullSec, classes.r2].join(' ')}>
              <div className={[classes.col, classes.right, classes.grid30].join(' ')}><h3>What does <br/>it cost?</h3></div>

              <div className={[classes.col, classes.left, classes.grid65].join(' ')}>
                <p>
                  $225.00 delivery charge for groceries totaling up to $250.<br/> Delivery fee goes up $10 per each additional $100 of groceries. 
                  <br/>
                  (i.e. $350 groceries = $235 delivery fee)
                </p>
              </div>
            </div>
              
          </div>
        </div>


        <div className={[classes.fullSec, classes.reviewRow].join(' ')}>
          <div className={classes.med2Def}>
            <p>"My family and I had a wonderful experience, thanks solely to this company! We had our fridge stocked - down to the type and brand of cheese I wanted - and 
              our beach was set up and taken down every single day. I have never enjoyed a higher level of care and attention than that received by 
              Gulf Shores Beach Rentals."</p>
            <p className={classes.name}>- Joshua K.</p>
          </div>
        </div>
        <div className={[classes.fullSec, classes.wave2].join(' ')}><img src={wave2} alt="wave border" /></div>

      </div>
    );
  }
}
export default Groceries;