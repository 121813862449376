import React, {Component} from 'react';
import { Link } from "react-router-dom";
import classes from "./Header.module.scss";
import NavItems from "./NavItems";
import Drawer from "./Drawer";
import Phone from "../Shared/Phone";
import Email from "../Shared/Email";
import BookNow from "../Shared/BookNow";
import Social from "../Social/Social";
import logo from "../../assets/images/gsbrLogo.png";

class Header extends Component {
  render() {
    return (
      <div className={classes.headerSec} data-uk-grid>
        <div className={[classes.fullSec, classes.topRow].join(' ')}>
          <div className={classes.tbarWrap}>
            <div className={[classes.ilHd, classes.phone].join(' ')}><Phone where="topbar" /></div>
            <span className={classes.dot}>&middot;</span>
            <div className={[classes.ilHd, classes.email].join(' ')}><Email where="topbar" /></div>
            <span className={[classes.dot, classes.two].join(' ')}>&middot;</span>
            <div className={[classes.ilHd, classes.book].join(' ')}><BookNow where="topbar" addClass="texty" /></div>
          </div>
        </div>

        <header className={classes.header}>  
          <div className={classes.medDef}>
            <div className={[classes.fullSec, classes.topHeader].join(' ')}>
              <div className={[classes.mobNav, classes.ilAbs].join(' ')}><Drawer /></div>
              <div className={[classes.ilAbs, classes.extras, classes.hdIcons].join(' ')}>
                <div className={[classes.ilHd, classes.social].join(' ')}><Social where="header" /></div>
              </div>
            
              <Link to="/" className={[classes.hdLogo].join(' ')}><img src={logo} alt="Gulf Shores Beach Rentals logo - beach supplies on the AL Gulf Coast"/></Link>
  
              <div className={[classes.ilAbs, classes.book, classes.hdRight].join(' ')}><BookNow where="Header" addClass="texty" /></div>
            </div>

            <div className={[classes.fullSec, classes.navRow].join(' ')}>
              <nav className={[classes.navWrap, "oguknavbarcontainerXX"].join(' ')} data-uk-navbar>
                <NavItems where="header" navClass="nav" addClass="uk-navbar-nav" />
              </nav>
            </div>

          </div>
        </header>
      </div>
    );
  }
}
export default Header;