import React, {Component} from 'react';
import { Helmet } from "react-helmet";
// import { Link } from "react-router-dom";
import classes from './Airbnb.module.scss';
import ab1 from '../../assets/images/airbnb1a.jpg';
import ab2 from '../../assets/images/airbnb2a.jpg';
import ab3 from '../../assets/images/airbnb3a.jpg';

const arrow = [
  <div className={classes.arrow}>
    <span className={classes.linkText}>View on Airbnb </span>
    <svg baseProfile="tiny-ps" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 15" width="20" height="15">
      <path id="Shape_7Y" className="shp0" d="M18.71 8.22L-0.01 8.22L-0.01 6.73L18.71 6.73L18.71 8.22ZM12.48 14.98L11.43 13.92L17.88 7.47L11.43 1.03L12.48 -0.03L19.99 7.47L12.48 14.98Z" />
    </svg>
  </div>
]
const mediaQuery = window.matchMedia('(min-width:1200px)');
let drag = "";
if (mediaQuery.matches) {
  drag = "draggable:false;"
}

class Airbnb extends Component {
  render() {
    return (
      <div className={classes.Airbnb}>
        <Helmet>
          <title>Vacation Townhome Rentals in Gulf Shores, AL | Gulf Shores Beach Rentals</title>
          <meta name="description" content="Our private townhouses are perfect for your next Gulf Shores vacation! View our vacation homes on this page, and follow the link to book through Airbnb." />
        </Helmet>

        <div className={[classes.inside, classes.inside1].join(' ')}>
          <h1 className={classes.style}>Airbnb Listings</h1>

          {/* They are a duplex so if someone has more than 4 people can rent both sides  */}
          <div className={[classes.fullRow, classes.is1].join(' ')}>
            <div className={[classes.fullSec, classes.slideContainer].join(' ')} data-uk-slider={drag}>{/* data-uk-slider="draggable:false" */}
              <button className={[classes.slideNav, classes.prev, "uk-position-center-left uk-position-small XXuk-hidden-hover"].join(' ')} href="#" data-uk-slidenav-previous data-uk-slider-item="previous"></button>
              <button className={[classes.slideNav, classes.next, "uk-position-center-right uk-position-small XXuk-hidden-hover"].join(' ')} href="#" data-uk-slidenav-next data-uk-slider-item="next"></button>

              <div className={[classes.scWrap, "uk-position-relative uk-visible-toggle"].join(' ')} tabIndex="-1">
                <ul className={[classes.sliderItems, classes.abItems, "uk-slider-items uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-2@m uk-child-width-1-3@l XXuk-grid"].join(' ')}>
                  <li key="airbTownL" className={[classes.colLi, classes.c1].join(' ')}>
                    <a href="https://www.airbnb.com.au/rooms/35636570?adults=1&s=42&unique_share_id=70249B89-721C-41D2-8DCD-6F4704082E56" target="_blank" rel="noreferrer" className={classes.sitem}>
                      <div className={classes.imgWrap}><img src={ab1} alt="Private townhouse rental in Gulf Shores, Alabama" /></div>
                      <div className={classes.textbox}>
                        <p className={classes.abhd}>Charming townhome steps <br/>from the beach!</p>
                        <p className={classes.sm}>Gulf Shores, AL</p>
                        {arrow}
                      </div>
                    </a>
                  </li>

                  <li key="airbTownR" className={[classes.colLi, classes.c2].join(' ')}>
                    <a href="https://www.airbnb.com.au/rooms/805097707935329802?adults=1&s=42&unique_share_id=F60A20B1-4BFB-4BA8-9EAF-6C64F6BE5011" target="_blank" rel="noreferrer" className={classes.sitem}>
                      <div className={classes.imgWrap}><img src={ab2} alt="Airbnb vacation home in Gulf Shores, Alabama" /></div>
                      <div className={classes.textbox}>
                        <p className={classes.abhd}>Townhome steps from the beach!</p>
                        <p className={classes.sm}>Gulf Shores, AL</p>
                        {arrow}
                      </div>
                    </a>
                  </li>

                  <li key="airbJK" className={[classes.colLi, classes.c3].join(' ')}>
                    <a href="https://abnb.me/XDdu7CUfoxb" target="_blank" rel="noreferrer" className={classes.sitem}>
                      <div className={classes.imgWrap}><img src={ab3} alt="Gulf Shores vacation home for rent" /></div>
                      <div className={classes.textbox}>
                        <p className={classes.abhd}>Run for the Roses</p>
                        <p className={classes.sm}>Gulf Shores, AL</p>
                        {arrow}
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>   
          </div>
        </div>
      </div>
    );
  }
}
export default Airbnb;