import React from "react";
import classes from './Social.module.scss';
// import appStyles from "../../App.module.scss";

const social = (props) => {
  return (
    <ul className={[classes.Social, classes[props.where]].join(' ')}>
      <li><a href="https://www.facebook.com/GSBeachRentals/" target="_blank" rel="noopener noreferrer">
        <span className={classes.SocialIcon} aria-label="Gulf Shores Beach Rentals Facebook Link" uk-icon="icon: facebook"></span>
      </a></li>

       <li><a href="https://www.instagram.com/gulfshoresbeachrentals/" target="_blank" rel="noopener noreferrer">
        <span aria-label="Gulf Shores Beach Rentals Instagram Link" uk-icon="icon: instagram"></span>
      </a></li>
    </ul>
  );
};

export default social;
