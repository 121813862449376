import React, {Component} from 'react';
import { Helmet } from "react-helmet";
// import { Link } from "react-router-dom";
import classes from './Info.module.scss';
import Email from '../../components/Shared/Email';
import Phone from '../../components/Shared/Phone';

class Info extends Component {
  render() {
    return (
      <div className={classes.Info}>
        <Helmet>
          <title>Beach Supply Rental Information | Gulf Shores Beach Rentals</title>
          <meta name="description" content="Learn more about Gulf Shores Beach Rentals, and our policies when renting beach chairs, kayaks & paddleboards, and other supplies for your Gulf Coast vacation." />
        </Helmet>

        <div className={[classes.fullSec, classes.title].join(' ')}>
          <h1 className={classes.style}>Rental Agreement</h1>
          <div className={classes.med3Def}>
            <p>
              Read through our rental agreement on the page below for more information on our policies. Feel 
              free to <Phone addClass="hoverLink" callText="call" /> or <Email where="hoverLink" text="email us" /> if you have any questions.
            </p>
          </div>
        </div>

        <div className={[classes.fullSec, classes.is1].join(' ')}>
          <hr className={classes.med} />
          <div className={classes.medDef}>        
            <ul>
              <li key="01">
                <h4>Cancellations &amp; Refunds:</h4>
                <p>We require seven (7) days prior to your arrival date in order to issue a refund. If you cancel with less than seven (7) days notice, only an 
                in-store credit can be issued. No refund will be issued with less than seven (7) days prior notice! No exceptions!</p>
              </li>
              <li key="02" className={classes.groupEnd}><p>A 5% fee will be charged on refunds to cover the cost of credit card processing fees. No refunds due to weather conditions other than hurricane policy noted below.</p></li>

              <li key="03" className={classes.groupEnd}>
                <h4>Hurricanes:</h4>
                <p>In the event of a Mandatory Evacuation Order, please <Phone where="info" addClass="hoverLink" callText="call us" /> to
                make arrangements for getting your equipment picked up. If you picked up the equipment yourself, you are responsible for returning the equipment to that location. A pro-rated in-store credit will be issued to you based on the amount of time remaining on your rental. Rental Equipment will be available upon returning to the area after the evacuation has ended, if applicable.</p>
              </li>     

              <li key="04" className={classes.groupEnd}>
                <h4>Additional Charges:</h4>
                <p>A $5.00 cleaning charge will be made on items returned unclean.</p>
              </li>

              <li key="05">
                <h4>General:</h4>
                <p>Gulf Shores Beach Rentals, LLC, along with its employees and subcontractors, are not responsible for any damage that may occur to the interior or exterior of your vehicle during loading, unloading, or transporting your rental equipment.</p>
              </li>
              <li key="06"><p>All equipment is used at Lessee's risk. The Lessee acknowledges that Lessee has inspected the equipment and has determined that equipment is in good condition and suitable for the purpose which it has been leased. the Lessee shall only use the property in the manner for which it is designed and intended to be used. Lessee recognizes that the property is being received as is and with all faults.</p></li>
              <li key="07"><p>Lessor makes no representations, promises, or warranties, either expressed or implied, concerning the merchantability, suitability or fitness for a party concerning the merchantability, suitability, or fitness for a particular purpose of the property. Lessor expressly disclaims any and all representations and warranties, whether arising from statute, common law, custom, or otherwise.</p></li>
              <li key="08"><p>Lessee assumes all responsibility for equipment while out of possession of Lessor and promises to return such equipment to the Lessor in as good condition as it was at the effective date of the lease, natural wear from responsible use expected. Lessee shall be liable for any loss, theft or damage of leased property. All equipment lost or damaged beyond repair will be paid by the Lessee at regular replacement price. All damaged equipment which can be repaired, will be paid by the Lessor, on return thereof and the cost for such repairs shall be paid by the Lessee. Accrued rental charges cannot be applied against the purchase price or cost of repairs of such damaged or lost equipment. Scheduled rental rates begin when the equipment leaves our store and continues until returned. Lessee shall indemnify and hold Lessor harmless from any and all claims, damages, or proceedings (including all costs, expenses and attorney fees) related to or arising out of Lessor's use or possession of equipment. This equipment shall be used by the Lessee only.</p></li>
              <li key="09"><p>This writing contains and constitutes the entire agreement between the parties. This lease agreement can only be modified by written agreement signed by both Lessor and Lessee. The Lessee represents that Lessee is at least eighteen (18) years of age and under no disability which will prevent Lessee from entering into this agreement.</p></li>
              <li key="10"><p>Lessee agrees not to part with or assign this lease or the equipment without the written consent of Lessor.</p></li>
              {/* doesn't make sense? */}
              <li key="11"><p>All rentals, cash in advance-we sell time-we charge all time out, including weekends and holidays. Save money, return rentals promptly in clean, good-working order.</p></li>
            </ul>
          
          </div>
        </div>
      </div>
    );
  }
}
export default Info;