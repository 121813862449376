import React from 'react';
import Drawer from '@material-ui/core/Drawer';
// import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import classes from "./Header.module.scss";
import styles from "../Footer/Footer.module.scss";
import Phone from "../Shared/Phone";
import BookNow from "../Shared/BookNow";
import Email from "../Shared/Email";
import Social from "../Social/Social";
import NavItems from "./NavItems.js";

function TemporaryDrawer(props) {
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const sideList = side => (
    <div
      className={[classes.list, classes.SideNavList].join(' ')}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <div className={classes.closeIcon}><ClearIcon /></div>
      <div className={classes.drawerBook}><BookNow where="drawer" addClass="XXgsLink" /></div>
      <NavItems navClass="SideNav" altOrder="true" />
    </div>
  );

  return (
    <div className={[classes.NavDrawer, styles.ftDrawer].join(' ')}>
      <button className={classes.drawerButton} onClick={toggleDrawer('left', true)}>
        <span className={classes.menuIcon} data-uk-icon="icon:menu;"></span>
        {props.addText ? props.addText : ''}
      </button>
     
      <Drawer anchor="left" open={state.left} onClose={toggleDrawer('left', false)} className={classes.cNavDrawer}>
        {/* <div className={classes.drawerBook}><BookNow where="drawer" addClass="XXgsLink" /></div> */}

        {sideList('left')}

        <div className={classes.drawerContact}>
          <hr/>
          <p className={[classes.dcItem, classes.dcEmail].join(' ')}><Email where="drawer" styleParent="mobileNav" /></p>
          <p className={classes.dcItem}><Phone where="drawer" styleParent="mobileNav" /></p>
          <p><Social where="drawer" styleParent="mobileNav" /></p>
        </div>
      </Drawer>
    </div>
  );
}

export default TemporaryDrawer;