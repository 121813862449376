import React, {useState} from 'react';
import classes from '../../App.module.scss';

const ScrollButton = () => {
  const [visible, setVisible] = useState(false)

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300){
    setVisible(true)
    }
    else if (scrolled <= 300){
    setVisible(false)
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
    top: 0,
    behavior: 'smooth' 
    });
  };

  window.addEventListener('scroll', toggleVisible);

  return (
    <button className={[classes.toTopBtn, classes.XXhide].join(' ')} onClick={scrollToTop} style={{display: visible ? 'block' : 'none'}}>
      <span data-uk-icon="icon: chevron-up;" />
    </button>
  );
}
export default ScrollButton;