import React, {Component} from 'react';
import { Link } from "react-router-dom";
import classes from "./Footer.module.scss";
import RentalItems from "../../hoc/Rentals/RentalLoop";
// import NavItems from "../Header/NavItems";
import Drawer from "../Header/Drawer";
import Phone from "../Shared/Phone";
import Email from "../Shared/Email";
import Social from "../Social/Social";
import logo from "../../assets/images/gsbrLogo.png";

// can't scroll on messages
// const fbPlug = "https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FGSBeachRentals&tabs=timeline%2Cmessages&width=340&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=6070227446362119";
// {/* <div class="fb-page" data-href="https://www.facebook.com/GSBeachRentals" data-tabs="timeline,messages" data-width="" data-height="" data-small-header="true" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/GSBeachRentals" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/GSBeachRentals">Gulf Shores Beach Rentals</a></blockquote></div> */}

class Footer extends Component {
  render() {
    const menuLoop = RentalItems.map((item) => 
      <li key={item.id} className={[classes["item" + item.id]]}>
        <a href={item.link}>{item.footTitle ? item.footTitle : item.title}</a>
      </li>
    );
    return (
      <footer className={[classes.fullSec, classes.footWrap, classes[this.props.type]].join(' ')}>
        <div className={classes.inside}>
          <div className={[classes.fullRow, classes.topFt].join(' ')}>
            <div className={[classes.col, classes.colMain, classes.col2, classes.logoCol].join(' ')}>
              <Link to="/" className={classes.ftLogo}><img src={logo} alt="Gulf Shores Beach Rentals logo"/></Link>
            </div>

            <div className={[classes.col, classes.colMain, classes.col3, classes.contact].join(' ')}>
              <h5>5-7 Day Rentals</h5>
              <p className={classes.emailp}><Email where="footer" /></p>
              <div className={[classes.col, classes.phone].join(' ')}><p className={classes.phonep}><Phone where="footer" /></p></div>
              <div className={[classes.col, classes.soc].join(' ')}><Social where="footer" /></div>
              <ul className={classes.genMen}>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/rental-agreement">Rental Agreement</Link></li>
                <li><a href="#contactModal" data-uk-toggle="target: #contactModal">Contact</a></li>
              </ul>
              <div className={classes.drawerRow}><Drawer addText="Menu" /></div>
            </div>

            <div className={[classes.col, classes.colMain, classes.col1, classes.menu].join(' ')}>
              <ul className={classes.ftNav}>
                {menuLoop}
              </ul>
            </div>
          </div>
        </div>

        <div className={classes.footCopy}>
          <div className={classes.medDef}>
            <div className={classes.fcInner}>
              <p className={classes.copy}>&copy; {(new Date().getFullYear())}, Gulf Shores Beach Rentals. </p>
              <p className={classes.design}> Design by <a href="https://hsdesignhouse.com" target="_blank" rel="noreferrer">High Seas Design House.</a></p>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
export default Footer;